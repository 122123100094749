import React from 'react';
import {ThemeProvider} from 'styled-components';
import {Helmet} from 'react-helmet';
import themeSettings from '../styles/themeSettings';
import GlobalStyles from '../styles/globalStyles';
import Footer from "../components/footer/Footer";
import styled from 'styled-components';
import Menu from "../components/menu/Menu";
import InfiniteSlider from "../components/infiniteSlider/InfiniteSlider";
import {useSelector} from "react-redux";

const StyledTempContent = styled.div`
  width: 100%;
  height: 120vh;
  //background-color: black;
`

const Layout = ({children, pageContext}) => {
	const globals = pageContext?.globals?.[0] || [];
	const menuData = pageContext?.menu || [];
	const locale = pageContext?.locale || 'pl';
	const otherPagesPathsForLangChange = pageContext?.otherPagesPathsForLangChange || [];
	const isMenuSticky = useSelector((state) => state.menu.isMenuSticky);
	return (
		<ThemeProvider theme={themeSettings}>
			<GlobalStyles/>
			{!isMenuSticky && <InfiniteSlider data={globals}/>}
			<Menu data={menuData} globals={globals} locale={locale}
			      otherPagesPathsForLangChange={otherPagesPathsForLangChange}/>
			{children}
			<Footer globals={globals}/>
		</ThemeProvider>
	);
};

export default Layout;
