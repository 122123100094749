import { createSlice } from '@reduxjs/toolkit';

export const menuSlide = createSlice({
	name: 'menu',
	initialState: {
		isMenuSticky: false,
	},
	reducers: {
		setMenuSticky: (state, action) => {
			state.isMenuSticky = action.payload;
		},
	},
});

export const { setMenuSticky } = menuSlide.actions;

export default menuSlide.reducer;
