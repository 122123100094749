const themeSettings = {
	colors: {
		black: '#3C4245',
		white: '#FFF',
	},
	fontFamily: {
		montserrat: "'Montserrat', sans-serif",
	},
	fontWeight: {
		thin: '100',
		extraLight: '200',
		light: '300',
		regular: '400',
		medium: '500',
		semiBold: '600',
		bold: '700',
		extraBold: '800',
		black: '900'
	},
};

export default themeSettings;
