exports.components = {
  "component---src-templates-about-page-js": () => import("./../../../src/templates/aboutPage.js" /* webpackChunkName: "component---src-templates-about-page-js" */),
  "component---src-templates-aesthetic-medicine-page-js": () => import("./../../../src/templates/aestheticMedicinePage.js" /* webpackChunkName: "component---src-templates-aesthetic-medicine-page-js" */),
  "component---src-templates-contact-page-js": () => import("./../../../src/templates/contactPage.js" /* webpackChunkName: "component---src-templates-contact-page-js" */),
  "component---src-templates-cosmetology-page-js": () => import("./../../../src/templates/cosmetologyPage.js" /* webpackChunkName: "component---src-templates-cosmetology-page-js" */),
  "component---src-templates-cream-bar-page-js": () => import("./../../../src/templates/creamBarPage.js" /* webpackChunkName: "component---src-templates-cream-bar-page-js" */),
  "component---src-templates-home-page-js": () => import("./../../../src/templates/homePage.js" /* webpackChunkName: "component---src-templates-home-page-js" */),
  "component---src-templates-pricing-page-js": () => import("./../../../src/templates/pricingPage.js" /* webpackChunkName: "component---src-templates-pricing-page-js" */)
}

