import { configureStore } from '@reduxjs/toolkit';
import languageReducer from './languageSlice';
import menuReducer from './menuSlice';
import introLoaded from "./introLoadedSlice";

export default configureStore({
	reducer: {
		language: languageReducer,
		menu: menuReducer,
		introLoaded
	},
});
