import React from 'react';
import styled from 'styled-components';
import SmallText from "../common/SmallText";

const StyledFooterWrapper = styled.div`
  background-color: #EEE9E6;
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 90px 25px 155px;
  overflow: hidden;
  @media (min-width: 1280px) {
    height: 315px;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0 100px;
    z-index: 10;
  }
`

const StyledLettersWrapper = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 86px;
  z-index: 0;
  @media(min-width: 1280px) {
    height: 375px;
    top: 0;
  }
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: left top;
    @media(min-width: 1280px) {
      object-position: left top;
    }
  }
`

const StyledLogoWrapper = styled.div`
  position: relative;
  z-index: 1;
  display: none;
  @media(min-width: 1280px) {
    display: block;
  }
  img {
    width: 146px;
    height: 57px;
  }
`

const StyledTextsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  position: relative;
  z-index: 1;
  row-gap: 10px;
  column-gap: 1vw;
  @media(min-width: 1280px) {
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
  @media(min-width: 1440px) {
    column-gap: 3vw;
  }
  @media(min-width: 1600px) {
    column-gap: 4vw;
  }
`

const StyledSocialMediaWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  position: relative;
  z-index: 1;
  margin-top: 80px;
  @media(min-width: 1280px) {
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    margin: 0;
  }
`

const StyledFindUsText = styled(SmallText)`
  margin-right: 2vw;
  margin-bottom: 20px;
  @media(min-width: 1280px) {
    margin-bottom: 0;
  }
`

const StyledSocialMediaIconsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  column-gap: 30px;
  @media(min-width: 1280px) {
    column-gap: 24px;
  }
`;

const StyledSingleSocialMediaItem = styled.a`
	width: 20px;
  height: 20px;
  
  img {
    height: 20px;
  }
`;


const Footer = ({globals}) => {
	const logo = globals?.logo?.url || '';
	const ioiiPattern = globals?.ioiiPattern?.url || '';
	const footerAddress = globals?.footerAddress || '';
	const footerEmail = globals?.footerEmail || '';
	const footerFindUs = globals?.footerFindUs || '';
	const footerHours = globals?.footerHours || '';
	const footerLandlineNumber = globals?.footerLandlineNumber || '';
	const footerMobileNumber = globals?.footerMobileNumber || '';
	const socialMedia = globals?.socialMedia || [];
	return (
		<StyledFooterWrapper id="footer">
			<StyledLettersWrapper>
				<img src={ioiiPattern} alt='ioii-pattern'/>
			</StyledLettersWrapper>
			<StyledLogoWrapper>
				<img src={logo} alt='logo'/>
			</StyledLogoWrapper>
			<StyledTextsWrapper>
				<SmallText>{footerAddress}</SmallText>
				<SmallText>{footerHours}</SmallText>
				<SmallText>{footerLandlineNumber}</SmallText>
				<SmallText>{footerMobileNumber}</SmallText>
				<SmallText>{footerEmail}</SmallText>
			</StyledTextsWrapper>
			<StyledSocialMediaWrapper>
				<StyledFindUsText>{footerFindUs}</StyledFindUsText>
				<StyledSocialMediaIconsWrapper>
					{socialMedia.map((item, key) => {
						const name = item?.name || '';
						const logo = item?.logo?.url || '';
						const link = item?.link || '';
						return (
							<StyledSingleSocialMediaItem key={name} href={link} target="_blank">
								<img src={logo} alt={name}/>
							</StyledSingleSocialMediaItem>
						)
					})}
				</StyledSocialMediaIconsWrapper>
			</StyledSocialMediaWrapper>
		</StyledFooterWrapper>
	);
};

export default Footer;