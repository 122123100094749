import React from 'react';
import { Provider } from 'react-redux';
import Layout from './src/layout/Layout';
import store from './src/state/store';
import './src/styles/globalFonts.css';

export const wrapPageElement = ({ element, props }) => (
	<Provider store={store}>
		<Layout {...props}>{element}</Layout>
	</Provider>
);
