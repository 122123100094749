import React, {useState, useEffect, useRef} from 'react';
import styled from 'styled-components';
import useWindowSize from "../../hooks/useWindowSize";
import {Link, navigate} from "gatsby";
import getSlug from "../../utils/getSlug";
import {useDispatch, useSelector} from "react-redux";
import {setMenuSticky} from "../../state/menuSlice";
import useIsDesktop from "../../hooks/useIsDesktop";
import '../../styles/menuItemAnimation.css';

const StyledMenuWrapper = styled.nav`
  position: fixed;
  top: 0;
  left: 0;
  background-color: white;
  //height: 58px;
  width: 100vw;
  z-index: 100;
  display: flex;
  flex-direction: row;
  //justify-content: flex-end;
  justify-content: space-between;
  align-items: center;
  padding: 30px 20px 0;
  transition: opacity 0.3s ease-in-out, padding-top 0.3s ease-in-out;
  //opacity: 0;
  opacity: 1;
  height: 126px;
  @media (min-width: 768px) {
    padding: 30px 40px 0;
    height: 99px;
  }
  @media (min-width: 1280px) {
    padding: 30px 100px 0;
    height: 200px;
    transition: height 0.3s ease-in-out;
  }

  &.sticky {
    padding: 0 20px;
    transition: padding-top 0.3s ease-in-out;
    @media (min-width: 768px) {
      padding: 0 40px;
    }
    @media (min-width: 1280px) {
      height: 110px;
      transition: height 0.3s ease-in-out, padding-top 0.3s ease-in-out;;
      padding: 0 100px;
    }
  }

  &.visible {
    //opacity: 1;
  }
`;


const StyledLogoWrapper = styled.div`
  position: relative;
  z-index: 1;
  display: block;
  @media (min-width: 1280px) {
    display: block;
    transition: transform 0.3s ease-in-out;
    transform: translateX(-45px);
    &.menu-open {
      transition: transform 0.3s ease-in-out;
      transform: translateX(0);
    }
  }

  img {
    width: 120px;
    height: 46px;
    @media (min-width: 1280px) {
      width: 146px;
      height: 57px;
    }
  }
`

const StyledDarkLine = styled.div`
  width: 134px;
  height: 4px;
  background-color: black;
  transition: transform 0.5s ease-in-out;
  transform: scaleX(1);
  transform-origin: left;
  display: none;
  @media (min-width: 1280px) {
    display: block;
  }

  &.menu-open {
    transition: transform 0.5s ease-in-out;
    transform: scaleX(0);
    transform-origin: left;
  }
`

const StyledRightWrapper = styled.div`
  display: flex;
  height: 100%;
  //width: 134px;
  //transition: width 0.5s ease-in-out 2s;
`

const StyledMenuFullscreenMobileWrapper = styled.div`
  position: fixed;
  height: 100vh;
  width: 100%;
  top: 0;
  left: 0;
  overflow-x: hidden;
  z-index: 80;
  transition: transform 0.3s ease-in-out;
  transform: translateX(110%);
  padding: 22vh 40px calc(var(--vh) * 15) 45px;

  &.menu-open {
    transition: transform 0.3s ease-in-out;
    transform: translateX(0);
  }

  background-color: #EEE9E6;
  color: #888583;
  @media (min-width: 1280px) {
    display: none;
  }
`

const StyledSingleMenuMobileItem = styled.div`
  color: #888583;
  font-size: 25px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`

const StyledNavigationItemsContainer = styled.div`
  overflow: hidden;
  transform-origin: right;
  position: relative;
  height: 100%;
  width: 0;
  transition: width 0.5s ease-in-out;
  display: none;
  @media (min-width: 1280px) {
    display: inline-block;
  }

  &.open {
    transition: width 0.5s ease-in-out;
    transform-origin: right;
    width: calc(100vw - 200px - 150px - 90px);
  }

  &.overflow-y {
    overflow: visible;
  }
`

const StyledNavigationItemsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  column-gap: 37px;
  position: absolute;
  //width: 700px;
  height: 100%;
  top: 0;
  right: 0;
  transform-origin: top right;
  width: calc(100vw - 200px - 150px - 90px);
  padding-right: 50px;
`

const StyledSingleMenuItem = styled.li`
  cursor: pointer;
  color: #000;
  font-size: 14px;
  font-weight: 500;
  line-height: normal;

  &.services {
    position: relative;
  }
`

const StyledServicesSubmenu = styled.div`
  background-color: white;
  border-radius: 19px;
  box-shadow: 0px 10px 9px 0px rgba(0, 0, 0, 0.15);
  padding: 20px 25px;
  position: absolute;
  top: 30px;
  lefT: -25px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: max-content;
  row-gap: 7px;
  opacity: 0;
  //transform: scaleY(0);
  //transform-origin: top;
  transition: opacity 0.1s ease-in-out;

  &.submenu-visible {
    opacity: 1;
    //transform: scaleY(1);
    //transform-origin: top;
    transition: opacity 0.1s ease-in-out;

    * {
      &:first-child {
        opacity: 1;
        transition: opacity 0.1s ease-in-out 0.15s;
      }

      &:nth-child(2) {
        opacity: 1;
        transition: opacity 0.1s ease-in-out 0.22s;
      }

      &:last-child {
        opacity: 1;
        transition: opacity 0.1s ease-in-out 0.28s;
      }
    }
  }
`

const StyledServicesSubmenuItem = styled.p`
  color: #000;
  font-size: 14px;
  font-weight: 400;
  line-height: normal;
  opacity: 0;
  transition: opacity 0.2s ease-in-out
`

const StyledActionButtonsWrapper = styled.div`
  //margin-left: 50px;
  cursor: pointer;
  color: #000;
  font-size: 14px;
  font-weight: 500;
  line-height: normal;
  height: 100%;
  display: flex;
  align-items: center;
  position: relative;
  width: 60px;
`

const StyledMenuOpenText = styled.div`
  position: relative;
  display: flex;
  align-items: center;

  .lines-container {
    width: 14px;
    height: 10px;
    cursor: pointer;
    position: absolute;
    top: calc(50% - 4px);
    left: -20px;
  }

  .line {
    position: absolute;
    width: 2px;
    height: 9px;
    background-color: black;
    transition: transform 0.5s ease, opacity 0.5s ease;
    left: 50%;
  }

  .line:nth-child(1) {
    left: 0;
    transform: none;
  }

  .line:nth-child(2) {
    left: 6px;
  }

  .line:nth-child(3) {
    left: 12px;
  }

  .lines-container.menu-open .line:nth-child(1) {
    transform: rotate(45deg);
  }

  .lines-container.menu-open .line:nth-child(2) {
    transform: translateX(-6px) rotate(-45deg);
  }

  .lines-container.menu-open .line:nth-child(3) {
    opacity: 0;
  }
`

const StyledLanguageChange = styled.div`
  cursor: pointer;
  color: #000;
  font-size: 14px;
  font-weight: 500;
  line-height: normal;
  margin-left: 130px;
  margin-right: 50px;
	opacity: 0;
  pointer-events: none;
  &.mobile {
    color: #888583;
    font-size: 25px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin: 0;
  }
`

const StyledMobileContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  width: 100%;
`

const StyledNavigationMobileItemsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  row-gap: 18px;
  height: 100%;
  width: 100%;
  //padding-right: 50px;
`

const StyledMobileBottomItemsWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`

const StyledSocialMediaIconsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  column-gap: 30px;
`;

const StyledSingleSocialMediaItem = styled.a`
  width: 20px;
  height: 20px;

  img {
    height: 20px;
  }
`;

const StyledMobileServicesSubmenu = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  height: 100%;
  max-height: 0;
  row-gap: 14px;

  a:first-child {
    margin-top: 30px;
  }

  a:last-child {
    margin-bottom: 30px;
  }

  //transform: scaleY(0);
  transition: max-height 0.3s ease-in-out;
  overflow-y: hidden;

  &.submenu-visible {
    transition: max-height 0.3s ease-in-out;
    //transform: scaleY(1);
    overflow-y: auto;
    max-height: 200px;
  }
`

const StyledSingleMobileSubmenuItem = styled.div`
  color: #888483;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  position: relative;
  padding-left: 20px;

  &:before {
    content: '';
    position: absolute;
    width: 3px;
    height: 3px;
    border-radius: 50%;
    background-color: #888483;
    left: 3px;
    top: 50%;
  }
`

const Menu = ({data, globals, locale, otherPagesPathsForLangChange}) => {
	const menuRef = useRef();
	const windowSize = useWindowSize();
	const logo = globals?.logo?.url || '';
	const openMenu = globals?.openMenu || '';
	const closeMenu = globals?.closeMenu || '';
	const dispatch = useDispatch()
	const isMenuSticky = useSelector((state) => state.menu.isMenuSticky);
	const [isServicesSubmenuOpen, setIsServicesSubmenuOpen] = useState(false)
	const [isMenuOpen, setIsMenuOpen] = useState(false);
	const submenuRef = useRef();
	const navContainerRef = useRef();
	const socialMedia = globals?.socialMedia || []; //name, logo.url, link
	const isDesktop = useIsDesktop();
	useEffect(() => {
		if (isDesktop) return;
		if (isMenuOpen) {
			document.documentElement.style.overflow = 'hidden';
		} else {
			document.documentElement.style.overflow = '';
		}
	}, [isMenuOpen]);

	function useOnClickOutside(ref, handler) {
		useEffect(() => {
			const listener = (event) => {
				if (!ref.current || ref.current.contains(event.target)) {
					return;
				}
				handler(event);
			};
			document.addEventListener('mousedown', listener);
			document.addEventListener('touchstart', listener);
			return () => {
				document.removeEventListener('mousedown', listener);
				document.removeEventListener('touchstart', listener);
			};
		}, [ref, handler]);
	}

	useOnClickOutside(submenuRef, () => {
		if (isDesktop) {
			setIsServicesSubmenuOpen(false)
		}
	});
	const handleActionsOnScroll = () => {
		const windowWidth = window?.innerWidth || 0;
		// if (windowWidth >= 1280) {
		if (window.scrollY >= 140) {
			if (menuRef?.current) {
				menuRef.current.classList.add('sticky');
				dispatch(setMenuSticky(true))
			}
		} else {
			if (menuRef?.current) {
				menuRef.current.classList.remove('sticky');
				dispatch(setMenuSticky(false))
			}
		}
	};

	useEffect(() => {
		if (!windowSize.height || !windowSize.width) return;
		const vh = windowSize.height * 0.01;
		document.documentElement.style.setProperty('--vh', `${vh}px`);
	}, [windowSize]);

	useEffect(() => {
		window.addEventListener('scroll', handleActionsOnScroll);
		return () => {
			window.removeEventListener('scroll', handleActionsOnScroll);
		};
	}, []);

	const handleMenuItemClick = (menuItem, slug) => {
		if (menuItem?.treeChildren?.length > 0) {
			setIsServicesSubmenuOpen(!isServicesSubmenuOpen);
		} else {
			navigate(`/${getSlug(slug, locale)}`);
			setIsMenuOpen(false)
		}
	}

	useEffect(() => {
		if (typeof window !== 'undefined') {
			setTimeout(() => {
				if (isMenuOpen) {
					if(navContainerRef?.current) {
						navContainerRef.current.classList.add('overflow-y')
					}
				} else {
					if(navContainerRef?.current) {
						navContainerRef.current.classList.remove('overflow-y')
					}
				}
			}, 500)
		}
	}, [isMenuOpen])

	const locales = ['pl', 'en'];
	const otherLang = locales.filter((item) => item !== locale)[0];
	const otherLangSlug = otherPagesPathsForLangChange[otherLang];

	const renderChar = (char, charIndex) => (
		<span className="char" data-char={char} style={{ '--char-index': charIndex }} key={charIndex}>
      {char}
    </span>
	);

	const renderWord = (word, wordIndex, allWords) => (
		<React.Fragment key={wordIndex}>
		    <span className="word">
		      {word.split('').map(renderChar)}
		    </span>
			{wordIndex < allWords.length - 1 && <span>&nbsp;</span>}
		</React.Fragment>
	);

	return (
		<>
			<StyledMenuWrapper ref={menuRef}>
				<StyledDarkLine className={isMenuOpen ? 'menu-open' : ''}/>
				<StyledLogoWrapper className={isMenuOpen ? 'menu-open' : ''} as={Link} to={locale === 'pl' ? '/' : '/en'}>
					<img src={logo} alt='logo'/>
				</StyledLogoWrapper>
				<StyledRightWrapper className={isMenuOpen ? 'menu-open' : ''}>
					<StyledNavigationItemsContainer ref={navContainerRef} className={isMenuOpen ? 'open' : ''}>
						<StyledNavigationItemsWrapper className={isMenuOpen ? 'open' : ''}>
							<ul>
							{data.map((item, key) => {
								const pageName = item?.title || '';
								const slug = item?.link?.slug || '';
								const servicesChildrenItems = item?.treeChildren || [];
								const sortedServicesChildren = servicesChildrenItems.sort((a, b) => (a.position > b.position ? 1 : -1));
								return (
									<StyledSingleMenuItem className={servicesChildrenItems.length > 0 ? 'services' : ''}
									                      key={pageName}
										// onMouseOver={() => handleMenuItemHover(item)}
										                  onClick={() => handleMenuItemClick(item, slug)}>
										{/*<p>{pageName}</p>*/}
										<div className="link">
											{pageName.split(' ').map(renderWord)}
										</div>
										{servicesChildrenItems.length > 0 &&
											(
												<StyledServicesSubmenu
													ref={submenuRef}
													className={isServicesSubmenuOpen ? 'submenu-visible' : ''}
													// onMouseLeave={() => handleMenuItemLeave()}
												>
													{sortedServicesChildren.map((el) => {
														const title = el?.title || '';
														const slug = el?.link?.slug || ''
														return (
															<StyledServicesSubmenuItem as={Link}
															                           onClick={() => setIsMenuOpen(false)}
															                           to={`/${getSlug(slug, locale)}`}
															                           key={title}>{title}</StyledServicesSubmenuItem>
														)
													})}
												</StyledServicesSubmenu>

											)}
									</StyledSingleMenuItem>
								)
							})}
							</ul>
							<StyledLanguageChange as={Link}
							                      to={`/${getSlug(otherLangSlug, otherLang)}`}>{locale === 'pl' ? 'en' : 'pl'}</StyledLanguageChange>
						</StyledNavigationItemsWrapper>
					</StyledNavigationItemsContainer>
					<StyledActionButtonsWrapper onClick={() => setIsMenuOpen(!isMenuOpen)}>
						<StyledMenuOpenText>
							<p>{isMenuOpen ? closeMenu : openMenu}</p>
							<div className={isMenuOpen ? "lines-container menu-open" : 'lines-container'}>
								<div className="line"></div>
								<div className="line"></div>
								<div className="line"></div>
							</div>
						</StyledMenuOpenText>
					</StyledActionButtonsWrapper>
				</StyledRightWrapper>
			</StyledMenuWrapper>
			<StyledMenuFullscreenMobileWrapper className={isMenuOpen ? 'menu-open' : ''}>
				<StyledMobileContentWrapper>
					<StyledNavigationMobileItemsWrapper className={isMenuOpen ? 'open' : ''}>
						{data.map((item, key) => {
							const pageName = item?.title || '';
							const slug = item?.link?.slug || '';
							const servicesChildrenItems = item?.treeChildren || [];
							const sortedServicesChildren = servicesChildrenItems.sort((a, b) => (a.position > b.position ? 1 : -1));
							return (
								<StyledSingleMenuMobileItem
									className={servicesChildrenItems.length > 0 ? 'services' : ''}
									key={pageName}
									// onMouseOver={() => handleMenuItemHover(item)}
									onClick={() => handleMenuItemClick(item, slug)}>
									<p>{pageName}</p>
									{servicesChildrenItems.length > 0 &&
										(
											<StyledMobileServicesSubmenu
												// ref={submenuRef}
												className={isServicesSubmenuOpen ? 'submenu-visible' : ''}
												// onMouseLeave={() => handleMenuItemLeave()}
											>
												{sortedServicesChildren.map((el) => {
													const title = el?.title || '';
													const slug = el?.link?.slug || ''
													return (
														<StyledSingleMobileSubmenuItem as={Link}
														                               onClick={() => setIsMenuOpen(false)}
														                               to={`/${getSlug(slug, locale)}`}
														                               key={title}>{title}</StyledSingleMobileSubmenuItem>
													)
												})}
											</StyledMobileServicesSubmenu>

										)}
								</StyledSingleMenuMobileItem>
							)
						})}
					</StyledNavigationMobileItemsWrapper>
					<StyledMobileBottomItemsWrapper>
						<StyledLanguageChange as={Link} className="mobile"
						                      to={`/${getSlug(otherLangSlug, otherLang)}`}>{locale === 'pl' ? 'en' : 'pl'}</StyledLanguageChange>
						<StyledSocialMediaIconsWrapper>
							{socialMedia.map((item, key) => {
								const name = item?.name || '';
								const logo = item?.logoBrown?.url || '';
								const link = item?.link || '';
								return (
									<StyledSingleSocialMediaItem key={name} href={link} target="_blank">
										<img src={logo} alt={name}/>
									</StyledSingleSocialMediaItem>
								)
							})}
						</StyledSocialMediaIconsWrapper>
					</StyledMobileBottomItemsWrapper>
				</StyledMobileContentWrapper>
			</StyledMenuFullscreenMobileWrapper>
		</>
	);
};

export default Menu;