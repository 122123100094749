import { createGlobalStyle } from 'styled-components';

const GlobalStyles = createGlobalStyle`
    
  :root {
    --background-color: ${({ theme }) => theme.colors.white};
  }

  html {
    background-color: var(--background-color);
    padding: 0;
    overflow-y: auto;
    overflow-x: hidden;
  }

  body {
    padding: 0;
    margin: 0;
    position: relative;
    width: 100%;
  	font-family: ${({ theme }) => theme.fontFamily.montserrat};
    -webkit-tap-highlight-color: transparent !important;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: #F7F7F7;

    ::-webkit-scrollbar {
      background: transparent;
      width: 0;
    }
  }

  *, *::before, *::after {
    box-sizing: border-box;
  }

  textarea:focus, input:focus {
    outline: none;
  }

  h1, h2, h3, h4, h5, h6, p, ul {
    margin: 0;
    padding: 0;
  }

  ul {
    list-style-type: none;
  }

  a {
    text-decoration: none;
    color: inherit;
  }

`;

export default GlobalStyles;
