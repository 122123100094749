import { createSlice } from '@reduxjs/toolkit';

export const languageSlice = createSlice({
	name: 'language',
	initialState: {
		lang: 'pl',
	},
	reducers: {
		setLang: (state, action) => {
			state.lang = action.payload;
		},
	},
});

export const { setLang } = languageSlice.actions;

export default languageSlice.reducer;
