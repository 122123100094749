import { createSlice } from '@reduxjs/toolkit';

export const introLoaded = createSlice({
	name: 'introLoaded',
	initialState: true,
	reducers: {
		setIntroLoaded: (state, action) => action.payload,
	},
});

export const { setIntroLoaded } = introLoaded.actions;

export default introLoaded.reducer;
