import React from 'react';
import styled from 'styled-components';
import getHtmlFromRedactorField from '../../utils/getHtmlFromRedactorField';

const StyledCommonDescriptionWrapper = styled.div`
  color: ${({theme}) => theme.colors.black};
  font-family: ${({theme}) => theme.fontFamily.montserrat};
  font-size: 16px;
  font-weight: ${({theme}) => theme.fontWeight.regular};
  line-height: 30px;
  position: relative;

  &.white {
    color: ${({theme}) => theme.colors.white};
  }
  &.bold {
    font-weight: 600;
  }
  &.center {
    text-align: center;
  }
  &.right {
    text-align: right;
  }
  &.lh-normal {
    line-height: normal;
    z-index: 1;
  }
  &.treatments {
    font-size: 15px;
    @media(min-width: 400px) {
      font-size: 16px;
    }
  }
  &.uppercase {
    text-transform: uppercase;
  }

  @media (min-width: 1280px) {
    font-size: 15px;
    line-height: 30px;
    transition: transform 0.3s ease-in-out;
  }
  @media (min-width: 1440px) {
    font-size: 16px;
  }
`;

const SmallText = ({children, className}) => (
	<StyledCommonDescriptionWrapper className={className} dangerouslySetInnerHTML={getHtmlFromRedactorField(children)}/>
);

export default SmallText;
