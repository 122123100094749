import React from 'react';
import styled from 'styled-components';
import Marquee from "react-fast-marquee";
import Line from '../../assets/black-line.svg';

const StyledMarqueeWrapper = styled.div`
	.marquee {
		position: fixed;
		top: 0;
		left: 0;
		height: 30px;
		z-index: 101;
		background-color:#F6E9E9;
	}
`

const StyledSingleMarqueeContent = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-right: 15px;

  p {
    color: #000;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px;
    margin-left: 15px;
  }
`

const InfiniteSlider = ({data}) => {
	const infiniteSliderText = data?.infiniteSliderText || ''
	return (
		<StyledMarqueeWrapper>
			<Marquee speed={75} pauseOnHover={true} className='marquee'>
				<StyledSingleMarqueeContent><img alt='black-line' src={Line}/><p>{infiniteSliderText}</p>
				</StyledSingleMarqueeContent>
				<StyledSingleMarqueeContent><img alt='black-line' src={Line}/><p>{infiniteSliderText}</p>
				</StyledSingleMarqueeContent>
				<StyledSingleMarqueeContent><img alt='black-line' src={Line}/><p>{infiniteSliderText}</p>
				</StyledSingleMarqueeContent>
				<StyledSingleMarqueeContent><img alt='black-line' src={Line}/><p>{infiniteSliderText}</p>
				</StyledSingleMarqueeContent>
				<StyledSingleMarqueeContent><img alt='black-line' src={Line}/><p>{infiniteSliderText}</p>
				</StyledSingleMarqueeContent>
				<StyledSingleMarqueeContent><img alt='black-line' src={Line}/><p>{infiniteSliderText}</p>
				</StyledSingleMarqueeContent>
				<StyledSingleMarqueeContent><img alt='black-line' src={Line}/><p>{infiniteSliderText}</p>
				</StyledSingleMarqueeContent>
			</Marquee>
		</StyledMarqueeWrapper>
	);
};

export default InfiniteSlider;